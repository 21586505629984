<template>
  <div class="data-service flex-column">
    <Header></Header>
    <keep-alive exclude="DataDetails,DataArea">
      <router-view class="data-main flex-1"></router-view>
    </keep-alive>
    <wavesBg></wavesBg>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import wavesBg from "./components/footer.vue";
export default {
  name: 'DataService',
  components: {
    Header,
    wavesBg,
  },
  data () {
    return {

    };
  },
};
</script>

<style lang="scss">
@import '@/assets/css/data_service.scss';
@import '@/assets/css/data_service-media.scss';
.data-service {
  width: 100%;
  min-height: 100%;
  background: url('../../assets/images/data_service/bg.jpg') no-repeat
    center/100% 100%;
  position: relative;
  color: #fefefe;
}
.data-main {
  width: 97%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  bottom: 20px;
}
</style>
