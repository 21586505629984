<template>
  <div
    :class="[
      'data-s-header',
      isDataArea ? 'data-s-header_2' : 'data-s-header_1',
    ]"
  >
    <template v-if="!isDataArea">
      <h4 class="data-s-title">天津市知识产权金融公共服务平台</h4>
    </template>
    <template v-else>
      <div class="data-s-title">
        <h4>{{ $store.getters.dataCondition.map.name }}</h4>
        <h4 style="margin-top: 5px">知识产权质押融资大数据</h4>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  watch: {
    $route (to, from) {
      this.isDataArea = to.name === 'DataArea'
    },
  },
  data () {
    return {
      isDataArea: this.$route.name === 'DataArea'
    };
  },
};
</script>

<style scoped lang="scss">
.data-s-header {
  position: relative;
  top: -8px;
  width: 97%;
  margin: 0 auto 20px;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  &.data-s-header_1 {
    min-height: 99px;
    background-image: url('../../../assets/images/data_service/head.png');
  }
  &.data-s-header_2 {
    min-height: 110px;
    background-image: url('../../../assets/images/data_service/head_2.png');
    .data-s-title {
      font-size: 26px;
      top: 20%;
    }
  }
  .data-s-title {
    font-size: 33px;
    color: transparent;
    text-align: center;
    background-image: linear-gradient(0deg, #00ccff 0%, #dff8fe 100%);
    background-clip: text;
    -webkit-background-clip: text;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
